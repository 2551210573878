<template>
  <div class="manageRights">
      <router-view />
  </div>
</template>

<script>
export default {
  name: 'manageRights',
  components: {},
  data () {
    return {}
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="stylus">
#container
  width 500px
  height 500px
</style>
